import { LOCALES, ACTIVITIES } from "@/const"

export const validateInputs = (inputs) => {
    if(!inputs.locales?.length || inputs.locales.some(locale => !LOCALES.includes(locale))) return false

    if(inputs.bundleActivity){
        for(const activity of inputs.bundleActivity?.split(';')){
            if (!ACTIVITIES.includes(activity)) return false
        }
    }

    if (!inputs.bundleProduct1SKU || inputs.bundleProduct1SKU?.length !== 4) return false
    if (!inputs.bundleProduct2SKU || inputs.bundleProduct2SKU?.length !== 4) return false

    if (inputs.bundleProduct1Variant && inputs.bundleProduct1Variant.length !== 3) return false
    if (inputs.bundleProduct2Variant && inputs.bundleProduct2Variant.length !== 3) return false

    if (inputs.bundleProduct3SKU && inputs.bundleProduct3SKU.length !== 4) return false
    if (inputs.bundleProduct3Variant && inputs.bundleProduct3Variant.length !== 3) return false

    if(!inputs.bundleProduct1Image1Type || inputs.bundleProduct1Image1Type.length > 5) return false
    if(!inputs.bundleProduct1Image2Type || inputs.bundleProduct1Image2Type.length > 5) return false
    if(!inputs.bundleProduct2Image1Type || inputs.bundleProduct2Image1Type.length > 5) return false
    if(!inputs.bundleProduct2Image2Type || inputs.bundleProduct2Image2Type.length > 5) return false
    
    if(inputs.bundleProduct3SKU && (!inputs.bundleProduct3Image1Type || inputs.bundleProduct3Image1Type.length > 5)) return false
    if(inputs.bundleProduct3SKU && (!inputs.bundleProduct3Image2Type || inputs.bundleProduct3Image2Type.length > 5)) return false

    return true
}

export const validateCollectionGroupInputs = (inputs) => {
    if(!inputs.locales?.length || inputs.locales.some(locale => !LOCALES.includes(locale))) return false
    if (!inputs.collectionBundle || !Array.isArray(inputs.collectionBundle) || !inputs.collectionBundle.length) return false

    return true
}

export const validateBundleGroupInputs = (inputs) => {
    if(!inputs.locales?.length || inputs.locales.some(locale => !LOCALES.includes(locale))) return false
    if (!inputs.bundleGroupBundle || !Array.isArray(inputs.bundleGroupBundle) || !inputs.bundleGroupBundle.length) return false

    return true
}

export const validateCollectionInputs = (inputs) => {
    if(!inputs.locales?.length || inputs.locales.some(locale => !LOCALES.includes(locale))) return false
    return true
}

export const serializeProducts = inputs => {
    let products = {}
    for (const key in inputs) {
        if (key.startsWith('bundleProduct')) {
            const index = key.replace('bundleProduct', '')[0]
            const property = key.replace('bundleProduct', '').slice(1).toLowerCase()
            if (!products[index]) products[index] = {}
            products[index][property] = inputs[key]
        }
    }
    return Object.keys(products).map(key => products[key])
}

export const serializeBundles = (inputs, bundles) => {
    if (!inputs.collectionBundle) {
        return [];
    }
    const collectionBundleArray = typeof inputs.collectionBundle === 'string' 
        ? JSON.parse(inputs.collectionBundle) 
        : inputs.collectionBundle;

    if (!Array.isArray(bundles)) {
        return [];
    }

    const bundlesArray = collectionBundleArray.map(bundle => {
        const foundBundle = bundles.find(b => b.name === bundle.name);
        return foundBundle ? { id: foundBundle.id, name: foundBundle.name } : null;
    }).filter(Boolean);

    return bundlesArray;
}

export const serializeBundleGroups = (inputs, crossLocaleBundles, bundles) => {
    if (!inputs.bundleGroupBundle) {
        return [];
    }
    const bundleGroupBundleArray = typeof inputs.bundleGroupBundle === 'string' 
        ? JSON.parse(inputs.bundleGroupBundle) 
        : inputs.bundleGroupBundle;

    if (!Array.isArray(crossLocaleBundles) || !Array.isArray(bundles)) {
        return [];
    }

    const bundlesArray = bundleGroupBundleArray.map(bundle => {
        const foundBundle = crossLocaleBundles.find(b => b.name === bundle.name);
        const priceBundle = bundles.find(b => b.generic_name === bundle.name && inputs.locales.includes(b.view_id));
        if (foundBundle && priceBundle) {
            return {...foundBundle, price: priceBundle.price};
        } else if (foundBundle) {
            return foundBundle;
        }
        return null;
    }).filter(Boolean);

    return bundlesArray;
}

export const serializeCollectionGroups = (inputs, crossLocaleBundles, bundles) => {
    if (!inputs.collectionBundle) {
        return [];
    }
    const collectionGroupBundleArray = typeof inputs.collectionBundle === 'string' 
        ? JSON.parse(inputs.collectionBundle) 
        : inputs.collectionBundle;

    if (!Array.isArray(crossLocaleBundles) || !Array.isArray(bundles)) {
        return [];
    }

    const collectionGroupArray = collectionGroupBundleArray.map(bundle => {
        const foundBundle = crossLocaleBundles.find(b => b.name === bundle.name);
        const priceBundle = bundles.find(b => b.generic_name === bundle.name && inputs.locales.includes(b.view_id));
        if (foundBundle && priceBundle) {
            return {...foundBundle, price: priceBundle.price};
        } else if (foundBundle) {
            return foundBundle;
        }
        return null;
    }).filter(Boolean);

    return collectionGroupArray;
}

export const serializeInputs = async (products,data) => {
    const result = {}
    await products.forEach((product, index) => {
        result[`bundleProduct${index + 1}SKU`] = product.sku
        result[`bundleProduct${index + 1}Variant`] = product.variant
        result[`bundleProduct${index + 1}Fallback`] = product.fallback
        result[`bundleProduct${index + 1}Image1Type`] = data.plp_images.first[product.sku]
        result[`bundleProduct${index + 1}Image2Type`] = data.plp_images.second[product.sku]
    })
    return result
}

export const getUpdatedAtTimeString = (bundleUpdatedAt) => {
    const updatedAt = new Date(bundleUpdatedAt)
    return `${updatedAt.toLocaleDateString()} ${updatedAt.toLocaleTimeString()}`
}